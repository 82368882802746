import axios from "axios";
import { loadUser } from "./LoadUserAction";
import config from "../config/config";

export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: "LOGOUT_REQUEST",
    });

    //LOGIN
    const { data } = await axios.get(`${config.server_url}/users/logout`, {
      withCredentials: true,
    });

    const { success, message } = data;
    if (success) {
      localStorage.setItem("isAuthenticate", "false");
      alert(message);
      dispatch(loadUser());
    }
  } catch (error) {
    dispatch({
      type: "LOGOUT_FAILURE",
      payload: error.response.message,
    });
  }
};
