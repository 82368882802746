import React from 'react'
import Khata from '../../component/admin-khata/Khata'

const CreateKhata = () => {
  return (
    <div>
      <Khata/>
    </div>
  )
}

export default CreateKhata