import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import config from "../../../config/config";

const StockUpdatesGraph = () => {
  const [stockList, setStockList] = useState([]);
  const [stockListHistory, setStockListHistory] = useState([]);

  let stockListUpdates = [];

  //load data
  useEffect(() => {
    axios
      .get(`${config.server_url}/stock/mystock`, {
        withCredentials: true,
      })
      .then((res) => {
        setStockList(res.data.stocks);
        //remove the id stored id from local storage
        localStorage.removeItem("id");
      })
      .catch((e) => {
        // isAuthenticate === false && (navigation('/login'))
        console.log(e);
      });
  }, []);

  //load data
  useEffect(() => {
    axios
      .get(`${config.server_url}/stock/history`, {
        withCredentials: true,
      })
      .then((res) => {
        setStockListHistory(res.data.stocks);
        //remove the id stored id from local storage
        localStorage.removeItem("id");
      })
      .catch((e) => {
        // isAuthenticate === false && (navigation('/login'))
        console.log(e);
      });
  }, []);

  // console.log(stockListHistory);

  //   filter there result
  for (let i = 0; i < stockList.length; i++) {
    //  console.log(stockList[i])
    for (let j = 0; j < stockListHistory.length; j++) {
      if (stockList[i].productName === stockListHistory[j].productName) {
        stockListUpdates.push({
          Name: stockList[i].productName,
          Stock: stockListHistory[j].productQnty - (stockListHistory[j].productQnty - stockList[i].productQnty),
          Sale: stockListHistory[j].productQnty - stockList[i].productQnty,
        });
      }
    }
  }

  // console.log(stockListUpdates);

  return (
    <div style={{ width: '100%', height: 'auto' }}>
  {stockListUpdates.length > 0 && (
    <ResponsiveContainer width="100%" height={700}>
      <BarChart data={stockListUpdates}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Stock" fill="#8884d8" />
        <Bar dataKey="Sale" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  )}
</div>

  );
};

export default StockUpdatesGraph;
