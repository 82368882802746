import React from 'react'
import StockInventaryManagement from '../../component/stock-management/StockInventaryManagement'

const StockManagement = () => {
  return (
    <div>
      <StockInventaryManagement/>
    </div>
  )
}

export default StockManagement