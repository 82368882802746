import React from "react";

const StockCalCardForDashborad = ({ stock }) => {
  // Calculate total amount
  let totalAmount = 0;
  let totalWholeAmount = 0;
  let totalRetalAmount = 0;
  for (const item of stock) {
    totalAmount += item.totalAmount;
    totalWholeAmount += item.wholesaleAmount * item.productQnty;
    totalRetalAmount += item.retailAmount * item.productQnty;
  }

  return (
    <div className="overflow-x-auto">
      <table className="w-full bg-white mb-2">
        <thead>
          <tr className="text-gray-800">
            <th className="py-2 px-4 sm:px-2 md:px-4 lg:px-4 sm:py-1 md:py-1 lg:py-1 font-semibold">Product Count</th>
            <th className="py-2 px-4 sm:px-2 md:px-4 lg:px-4 sm:py-1 md:py-1 lg:py-1 font-semibold">Total Amount</th>
            <th className="py-2 px-4 sm:px-2 md:px-4 lg:px-4 sm:py-1 md:py-1 lg:py-1 font-semibold">Total Wholesale Amount</th>
            <th className="py-2 px-4 sm:px-2 md:px-4 lg:px-4 sm:py-1 md:py-1 lg:py-1 font-semibold">Total Retail Amount</th>
            <th className="py-2 px-4 sm:px-2 md:px-4 lg:px-4 sm:py-1 md:py-1 lg:py-1 font-semibold">Estimate Profit</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-gray-600">
            <td className="text-center py-2 px-4 sm:px-2 md:px-4 lg:px-4 sm:py-1 md:py-1 lg:py-1">{stock.length}</td>
            <td className="text-center py-2 px-4 sm:px-2 md:px-4 lg:px-4 sm:py-1 md:py-1 lg:py-1">{totalAmount.toFixed(2)}</td>
            <td className="text-center py-2 px-4 sm:px-2 md:px-4 lg:px-4 sm:py-1 md:py-1 lg:py-1">{totalWholeAmount.toFixed(2)}</td>
            <td className="text-center py-2 px-4 sm:px-2 md:px-4 lg:px-4 sm:py-1 md:py-1 lg:py-1">{totalRetalAmount.toFixed(2)}</td>
            <td className="text-center py-2 px-4 sm:px-2 md:px-4 lg:px-4 sm:py-1 md:py-1 lg:py-1">{(totalWholeAmount - totalAmount).toFixed(2)}</td>
          </tr>
          {/* Add more rows as needed */}
        </tbody>
      </table>
    </div>
  );
};

export default StockCalCardForDashborad;
