import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { IoMdAdd } from "react-icons/io";
// import { MdDelete } from "react-icons/md";
import { HiOutlinePencilAlt } from "react-icons/hi";
import StockCalculationBar from "../utilities/StockCalculationBar";
import config from "../../config/config";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Loader from "../loader/Loader";
import toast, { Toaster } from "react-hot-toast";

const StockInventaryManagement = () => {
  const navigate = useNavigate();
  const [stockList, setStockList] = useState([]);
  const [stockListForSearch, setStockListOnSearch] = useState([]);
  const [sureDeleteModel, setSureDeleteModel] = useState(false);
  // const [itemId, setItemId] = useState("");
  const [isLoaderStart, setIsLoaderStart] = useState(false);
  const [isOpenImportModel, setIsOpenImportModel] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const [refresh, setRefresh] = useState(false);

  // remove document id from localStorage
  localStorage.removeItem("documentId");

  //load stock
  useEffect(() => {
    setIsLoaderStart(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.server_url}/stock/mystock`, {
          withCredentials: true,
        });
        setStockList(response.data.stocks);
        setStockListOnSearch(response.data.stocks);
        setIsLoaderStart(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [refresh]);

  const handleUpdate = (id) => {
    // Update logic here
    alert(`Are you want to update the product details!`);

    // Store variable in local storage
    localStorage.setItem("documentId", id);
    navigate("../newStock");
  };

  //handle for delete the stock
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${config.server_url}/stock/${id}`, {
        withCredentials: true,
      });
      const { success, message } = response.data;
      if (success) {
        toast.success(message);
        setSureDeleteModel(false);
        setRefresh(true);
      }
    } catch (error) {
      toast.error(error.response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //handle for sure delete prompt
  // const handleOnSureDelete = (itemId) => {
  //   setSureDeleteModel(true);
  //   setItemId(itemId);
  // };

  //handle for close model
  const handleCloseModal = () => {
    setSureDeleteModel(false);
  };

  // handle search
  const handleSearch = (e) => {
    const searchTerm = e.target.value.trim().toLowerCase(); // Get the trimmed lowercase search term

    if (searchTerm === " ") {
      setStockList(stockList); // If the search term is empty, show the entire original array
    } else {
      // Filter the array based on the search term
      const tempVar = stockListForSearch?.filter((item) =>
        item.productName?.trim().toLowerCase().includes(searchTerm)
      );
      setStockList(tempVar); // Update the array state with the filtered results
    }
  };

  //handle on export
  const handleOnExport = async () => {
    try {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      // Function to export data to Excel

      const ws = XLSX.utils.json_to_sheet(stockList);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "bg_stock_list" + fileExtension);
    } catch (error) {
      // Handle network errors or unexpected issues
      console.error("An error occurred while exporting data:", error);
      toast.error("An error occurred while exporting data");
    }
  };

  // handle on import
  const handleOnImport = () => {
    setIsOpenImportModel(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setExcelData(file);
  };

  const handleOnImportModelClose = () => {
    setIsOpenImportModel(false);
  };

  const handleImport = async (e) => {
    e.preventDefault();

    // Get the file from the file input element
    const fileInput = document.querySelector('input[type="file"]');
    const file = fileInput.files[0];

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${config.server_url}/stock/new/upload`,
        formData, // Pass formData directly
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      console.log(response.data);
      const {success, message} = response.data
      if(success){
        toast.success(message)
        setIsOpenImportModel(false)
        setRefresh(true)
      }
      // Close modal or show success message
    } catch (error) {
      console.error(error);
      // Handle error
    }
  };

  return (
    <>
      {" "}
      {isLoaderStart ? (
        <Loader />
      ) : (
        <div className="bg-gray-50 px-4  h-screen">
          {/* totify container  */}
          <Toaster/>
          {/* <div>
      <HeadingTitle title={"Available Stock"} />
    </div> */}

          {/* stock calculate bar  */}
          <div>
            <StockCalculationBar stock={stockList} />
          </div>
          <div className="flex  justify-between mx-6">
            {/* handle search  */}
            <div className="w-full sm:w-64 md:w-72 lg:w-96 flex items-center border bg-white rounded-md p-1 mx-1 mb-2 lg:mb-0">
              <span className="text-lg mx-1">&#128269;</span>
              <input
                type="text"
                onChange={(e) => handleSearch(e)}
                placeholder="Search Product..."
                className="w-full rounded-lg outline-none bg-white"
              />
            </div>

            <div className="flex sm:flex-row gap-3">
              <div>
                <button
                  className="flex sm:text-sm md:text-md lg:text-md items-center justify-center py-1 px-6 sm:px-4 shadow-sm bg-white border rounded-full font-medium hover:bg-gray-100 hover:border"
                  onClick={() => handleOnExport()}
                >
                  Export
                </button>
              </div>
              <div>
                <button
                  className="flex sm:text-sm md:text-md lg:text-md items-center justify-center py-1 px-6 sm:px-4 shadow-sm bg-white border rounded-full font-medium hover:bg-gray-100 hover:border"
                  onClick={() => handleOnImport()}
                >
                  Import
                </button>
              </div>
              <div>
                <Link
                  to={"../newStock"}
                  className="flex sm:text-sm md:text-md lg:text-md items-center justify-center py-1 px-6 sm:px-4 shadow-sm bg-white border rounded-full font-medium hover:bg-gray-100 hover:border"
                >
                  <IoMdAdd className="mx-1" />
                  <button>New Stock</button>
                </Link>
              </div>
            </div>
          </div>

          {/* end handle search  */}
          <div className="px-1 overflow-x-auto">
            <table className="w-full p-2 mt-2">
              <thead className="bg-white">
                <tr>
                  <th className="px-2 py-3 border border-r border-gray-600 text-left text-sm  text-gray-600 ">
                    S.No.
                  </th>
                  <th className="px-2 py-3 border border-r border-gray-600 text-left text-sm  text-gray-600 ">
                    Product Name
                  </th>
                  <th className="px-2 py-3 border border-r border-gray-600 text-left text-sm font-bold text-gray-600 ">
                    Unit
                  </th>
                  <th className="px-2 py-3 border border-r border-gray-600 text-left text-sm font-bold text-gray-600 ">
                    Kharid Amt
                  </th>
                  <th className="px-2 py-3 border border-r border-gray-600 text-left text-sm font-bold text-gray-600 ">
                    Quantity
                  </th>
                  <th className="px-2 py-3 border border-r border-gray-600 text-left text-sm font-bold text-gray-600 ">
                    Total Amt
                  </th>
                  <th className="px-2 py-3 border border-r border-gray-600 text-left text-sm font-bold text-gray-600 ">
                    Wholesale Rate
                  </th>
                  <th className="px-2 py-3 border border-r border-gray-600 text-left text-sm font-bold text-gray-600 ">
                    Retail Rate
                  </th>
                  <th className="px-2 py-3 border border-r border-gray-600 text-left text-sm font-bold text-gray-600 ">
                    Distributer Name
                  </th>
                  <th className="px-2 py-3 border border-r border-gray-600 text-left text-sm font-bold text-gray-600 ">
                    Update Date
                  </th>
                  <th className="px-2 py-3 border  border-gray-600 text-left text-sm font-bold text-gray-600 ">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {stockList
                  ?.sort((a, b) => {
                    const dateObjectA = new Date(a.createdAt);
                    const dateObjectB = new Date(b.createdAt);

                    const timeAInSeconds =
                      dateObjectA.getHours() * 3600 +
                      dateObjectA.getMinutes() * 60 +
                      dateObjectA.getSeconds();
                    const timeBInSeconds =
                      dateObjectB.getHours() * 3600 +
                      dateObjectB.getMinutes() * 60 +
                      dateObjectB.getSeconds();

                    return timeAInSeconds - timeBInSeconds;
                  })
                  .map((item, index) => (
                    <tr
                      key={index}
                      className={`border-b ${
                        index % 2 ? "bg-slate-50" : "bg-white"
                      }`}
                    >
                      <td className="px-2 py-2 ">{index + 1}</td>
                      <td className="px-2 py-2 ">{item.productName}</td>
                      <td className="px-2 py-2 ">{item.productUnitType}</td>
                      <td className="px-2 py-2 ">
                        {item.kharidAmount.toFixed(2)}
                      </td>
                      <td className="px-2 py-2 ">{item.productQnty}</td>
                      <td className="px-2 py-2 ">
                        {item.totalAmount.toFixed(2)}
                      </td>
                      <td className="px-2 py-2 ">
                        {item.wholesaleAmount.toFixed(2)}
                      </td>
                      <td className="px-2 py-2 ">
                        {item.retailAmount.toFixed(2)}
                      </td>
                      <td className="px-2 py-2 ">
                        {item.distributerName.toUpperCase()}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap">
                        {item.productExpiryDate}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          onClick={() => handleUpdate(item._id)}
                          className="p-2 text-white rounded mr-2 bg-green-600 uppercase "
                        >
                          <HiOutlinePencilAlt />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {/* Showing Sure  team Model  */}
          {/* {sureDeleteModel && (
            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-screen">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={handleCloseModal}
                >
                  <div className="absolute inset-0 bg-black opacity-80"></div>
                </div>
                <div className="relative bg-white rounded-lg px-6 py-2 mx-auto">
                  <div className="flex flex-col text-center justify-between">
                    <div className=" mt-1 text-slate-600 text-xl">
                      Product stock about to be deleted.
                    </div>
                    <span className="text-slate-600 text-[12px] ">
                      Are you sure you want to proceed?
                    </span>
                    <div className="mt-4 flex flex-row mx-auto gap-12 justify-between">
                      <button
                        className="bg-green-500 hover:bg-green-700 text-white py-1 px-4 rounded"
                        onClick={() => handleDelete(itemId)}
                      >
                        Yes
                      </button>
                      <button
                        className="bg-red-500 hover:bg-red-700 text-white  py-1 px-4 rounded"
                        onClick={handleCloseModal}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      )}
      {/* import model  */}
      {isOpenImportModel && (
        <form className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
          <div className="w-full max-w-lg p-4 bg-white shadow-lg rounded-md">
            <div className="flex justify-between items-center mb-4">
              <h1 className="text-lg font-semibold">Import Excel File</h1>
              <button
                onClick={handleOnImportModelClose}
                className="text-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
              className="py-2 px-4 border border-gray-300 rounded-md mb-4"
            />
            <div className="flex justify-end">
              <button
                onClick={handleOnImportModelClose}
                className="px-4 py-2 bg-gray-300 text-white rounded-md mr-2"
              >
                Close
              </button>
              <button
                type="submit"
                onClick={(e) => handleImport(e)}
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
              >
                Import
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default StockInventaryManagement;
