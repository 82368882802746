import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeadingTitle from "../heading/HeadingTitle";
import { Link } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import config from "../../config/config";
import Loader from "../loader/Loader";

const InvoiceManagement = () => {
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceListForSearch, setInvoiceListForSearch] = useState([]);
  const [isLoaderStart, setIsLoaderStart] = useState(false);

  // remove document id from localStorage
  localStorage.removeItem("documentId");

  //load all the incoice
  useEffect(() => {
    setIsLoaderStart(true);
    try {
      axios
        .get(`${config.server_url}/invoice/all`, {
          withCredentials: true,
        })
        .then((res) => {
          setInvoiceList(res.data.invoices);
          setInvoiceListForSearch(res.data.invoices);
          setIsLoaderStart(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  // handle search
  const handleSearch = (e) => {
    const searchTerm = e.target.value.trim().toLowerCase(); // Get the trimmed lowercase search term

    if (searchTerm === " ") {
      setInvoiceList(invoiceList); // If the search term is empty, show the entire original array
    } else {
      // Filter the array based on the search term
      const tempVar = invoiceListForSearch?.filter((item) =>
        item.invoiceId?.trim().toLowerCase().includes(searchTerm)
      );
      setInvoiceList(tempVar); // Update the array state with the filtered results
    }
  };

  return (
    <>
      {isLoaderStart ? (
        <Loader />
      ) : (
        <div className="w-full p-4 bg-gray-50  h-screen">
          <ToastContainer />
          {/* <HeadingTitle title={" Manage Invoices"} /> */}

          {/* handle search  */}
          <div className="flex justify-between">
            <div className="w-96 flex items-center border bg-white rounded-md p-1 mx-1">
              <span className="text-lg mx-1">&#128269;</span>
              <input
                type="text"
                onChange={(e) => handleSearch(e)}
                placeholder="Search Invoice Id..."
                className="w-96 p-1 rounded-lg outline-none "
              />
            </div>
            <div className="px-4">
              <Link
                to={"../generateInvoice"}
                className="flex items-center py-1 px-6 shadow-sm bg-white border rounded-full font-medium hover:bg-gray-100 hover:border"
              >
                <IoMdAdd className="mx-1" />
                <button>New Invoice</button>
              </Link>
            </div>
          </div>

          {/* end handle search  */}

          <div className="w-full mx-auto mt-5">
            <table className="min-w-full bg-white border">
              <thead className="p-4">
                <tr>
                  <th className="py-2 px-4 border-b">S.No.</th>
                  <th className="py-2 px-4 border-b">Invoice ID</th>
                  <th className="py-2 px-4 border-b">Customer Name</th>
                  <th className="py-2 px-4 border-b">Total Amount</th>
                  <th className="py-2 px-4 border-b">Paid Amount</th>
                  <th className="py-2 px-4 border-b">Due Amount</th>
                  <th className="py-2 px-4 border-b">Payment Status</th>
                  <th className="py-2 px-4 border-b">Created At</th>
                </tr>
              </thead>
              <tbody>
                {invoiceList.map((invoice, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b">{index + 1}</td>
                    <td className="py-2 px-4 border-b">{invoice.invoiceId}</td>
                    <td className="py-2 px-4 border-b">
                      {invoice.customerName}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {invoice.totalAmount}
                    </td>
                    <td className="py-2 px-4 border-b">{invoice.paidAmount}</td>
                    <td className="py-2 px-4 border-b">{invoice.dueAmount}</td>
                    <td className="py-2 px-4 border-b">
                      {invoice.isPaymentDone ? "Paid" : "Unpaid"}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {new Date(invoice.createdAt).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceManagement;
