import React, { useEffect, useState } from "react";
import axios from "axios";
import HeadingTitle from "../../component/heading/HeadingTitle";
import SalesCard from "../../component/admin-sales-card/SalesCard";
import SaleMonthlyLineGraph from "../../component/graph/line/SaleMonthlyLineGraph";
import StockUpdatesGraph from "../../component/graph/bar/StockUpdatesGraph";
import OutOfStockProductList from "../../component/admin-stock-inventary/OutOfStockProductList";
import StockCalCardForDashborad from "../../component/utilities/StockCalCardForDashborad";
import config from "../../config/config";
import Loader from "../../component/loader/Loader";

const Home = () => {
  const [invoiceList, setIvoiceList] = useState([]);
  const [allIKhataInvoice, setAllKhataInvoice] = useState([]);
  const [stockList, setStockList] = useState([]);
  const [isLoaderStart, setIsLoaderStart] = useState(false);

  //load stock
  useEffect(() => {
    setIsLoaderStart(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.server_url}/stock/mystock`, {
          withCredentials: true,
        });
        setStockList(response.data.stocks);
        setIsLoaderStart(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // all invoice of no registered customer
  //load all the incoice
  useEffect(() => {
    setIsLoaderStart(true);
    try {
      axios
        .get(`${config.server_url}/invoice/all`, {
          withCredentials: true,
        })
        .then((res) => {
          setIvoiceList(res.data.invoices);
          setIsLoaderStart(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  // calcualte total amount
  let totalSaleFromInvoiceOnly = 0;
  let totalDueAmountFromInvoiceOnly = 0;
  let totalKharidAmount = 0;
  let totalWholeSaleProfit = 0;

  if (invoiceList.length > 0) {
    for (let i = 0; i < invoiceList.length; i++) {
      totalSaleFromInvoiceOnly =
        totalSaleFromInvoiceOnly + invoiceList[i].totalAmount;
      totalDueAmountFromInvoiceOnly =
        totalDueAmountFromInvoiceOnly + invoiceList[i].dueAmount;

      //for calculate revenue
      for (let item = 0; item < invoiceList[i].itemList.length; item++) {
        totalKharidAmount =
          totalKharidAmount + invoiceList[i].itemList[item].itemPurchasedRate;
        totalWholeSaleProfit =
          totalWholeSaleProfit +
          invoiceList[i].itemList[item].itemWholeSaleRate;
      }
    }
  }

  //load all khata invoices
  //load all the incoice
  useEffect(() => {
    setIsLoaderStart(true);
    try {
      axios
        .get(`${config.server_url}/khata/all`, {
          withCredentials: true,
        })
        .then((res) => {
          setAllKhataInvoice(res.data.khataInvoices);
          setIsLoaderStart(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  // console.log(allIKhataInvoice);

  let totalAmountFromKhata = 0;
  let totalPurchasedAmtFromKhata = 0;
  let totaldueAmtFromKhata = 0;
  let totalSaleProductFromKhata = 0;

  if (allIKhataInvoice.length > 0) {
    for (let i = 0; i < allIKhataInvoice.length; i++) {
      // total amount
      totalAmountFromKhata =
        totalAmountFromKhata + allIKhataInvoice[i].totalAmount;
      //due amount
      totaldueAmtFromKhata =
        totaldueAmtFromKhata + allIKhataInvoice[i].dueAmount;

      //for calculate revenue
      for (let item = 0; item < allIKhataInvoice[i].itemList.length; item++) {
        totalPurchasedAmtFromKhata =
          totalPurchasedAmtFromKhata +
          allIKhataInvoice[i].itemList[item].itemPurchasedRate;
        totalSaleProductFromKhata =
          totalSaleProductFromKhata +
          allIKhataInvoice[i].itemList[item].itemWholeSaleRate;
      }
    }
  }

  return (
    <>
      {isLoaderStart ? (
        <Loader />
      ) : (
        <div className="bg-gray-50 p-4">
          {/* <HeadingTitle title={"Stock Inventary"} /> */}
          <section>
            <StockCalCardForDashborad stock={stockList} />
          </section>

          {/* <HeadingTitle title={"Today Sale Report"} /> */}
          {/* sale report  */}
          <section className="grid grid-cols-12 mb-2">
            <div className="lg:col-span-3 md:col-span-6 col-span-12">
              <SalesCard
                totalAmount={
                  totalWholeSaleProfit -
                  totalKharidAmount +
                  (totalSaleProductFromKhata - totalPurchasedAmtFromKhata)
                }
                moneyColor={`text-gray-500`}
                bgColor={`bg-blue-50`}
                titleColor={`text-gray-500`}
                rupeesSymbol={"₹"}
                title={"Total Revenue (W.Sale)"}
              />
            </div>
            <div className="lg:col-span-3 md:col-span-6 col-span-12">
              <SalesCard
                totalAmount={
                  totalSaleFromInvoiceOnly -
                  totalKharidAmount +
                  (totalAmountFromKhata - totalPurchasedAmtFromKhata)
                }
                moneyColor={`text-gray-500`}
                bgColor={`bg-green-50`}
                titleColor={`text-gray-500`}
                title={"Total Revenue (Retail)"}
                rupeesSymbol={"₹"}
              />
            </div>
            <div className="lg:col-span-3 md:col-span-6 col-span-12">
              <SalesCard
                totalAmount={
                  totalDueAmountFromInvoiceOnly + totaldueAmtFromKhata
                }
                moneyColor={`text-gray-500`}
                bgColor={`bg-red-50`}
                titleColor={`text-gray-500`}
                title={"Total Due Amount"}
                rupeesSymbol={"₹"}
              />
            </div>
            <div className="lg:col-span-3 md:col-span-6 col-span-12">
              <SalesCard
                totalAmount={totalSaleFromInvoiceOnly + totalAmountFromKhata}
                moneyColor={`text-yellow-500`}
                bgColor={`bg-green-50`}
                titleColor={`text-gray-500`}
                title={"Total Sales Amount"}
                rupeesSymbol={"₹"}
              />
            </div>
          </section>

          {/* todays sale report end  */}

          {/* <HeadingTitle title={"This Month sale (Invoice)"} /> */}
          {/* sale report on graph */}
          <section className="p-4 bg-white">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              {/* invoice list sale history of this month  */}
              {/* <div>
                <SaleMonthlyLineGraph />
              </div> */}

              {/* khata invoice sale history */}
              {/* <div className="col-span-1">
        <div className="flex justify-start ">
          <span className="p-4 text-lg font-bold text-green-600 uppercase">
            This Month sale (khata)
          </span>
        </div>
        <SaleMonthlyKhataLine />
      </div> */}
            </div>
          </section>

          {/* stock updates  */}
          {/* <HeadingTitle title={"Stock Updates"} /> */}
          <section>
            <div className="bg-white">
              {/* title  */}
              {/* <div className="flex justify-start">
                <span className="mx-8 text-lg text-green-700 font-bold uppercase">Stock Updates</span>
            </div> */}

              <div className="p-10">
                <StockUpdatesGraph />
              </div>
            </div>
          </section>

          {/* out of stock panel  */}
          {/* <HeadingTitle title={"Stock Updates"} /> */}
          <section>
            <div className="bg-white">
              {/* title  */}
              {/* <div className="flex justify-start">
                <span className="mx-8 text-lg text-green-700 font-bold uppercase">Out of Stock Products</span>
            </div> */}

              <div className="p-10">
                <OutOfStockProductList />
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Home;
