import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { taost, Toaster } from "react-hot-toast";
import logo from "../../assets/BirulyGrocery.png";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/LogoutAction";
import axios from "axios";
import config from "../../config/config";

const TopNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [path, setPath] = useState(location?.pathname);
  const [profile, setProfile] = useState({});
  const [sureLogoutModel, setSureLogoutModel] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  useEffect(() => {
    setPath(location?.pathname?.split("/")[2]);
  }, [location.pathname]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${config.server_url}/users/me`, {
          withCredentials: true,
        });
        const { success, user } = response.data;
        if (success) {
          setProfile(user);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, []);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await dispatch(logout());
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleCloseModal = () => {
    setSureLogoutModel(false);
  };

  // handle for profile
  const handleOnprofile = () => {
    setIsProfileOpen(true);
  };

  //handle for close profile
  const handleOnCloseProfile = () => {
    setIsProfileOpen(false);
  };

  return (
    <>
      <Toaster />
      <nav className="flex justify-between items-center bg-white p-1 shadow-md">
        <div className="flex items-center">
          <img src={logo} alt="logo" className="w-16 sm:w-20 mr-4" />
          <Link
            to="./home"
            className="text-lg sm:text-xl font-bold text-[#39B54A] font-sans"
          >
            BirulyGrocery
          </Link>
        </div>
        <div className="flex items-center">
          <div className="overflow-x-auto">
            <ul className="flex mx-2 sm:mx-6">
              <li className="mx-1 sm:mx-2 cursor-pointer px-2 sm:px-3 py-1 rounded-full border hover:bg-gray-50">
                <Link to={"./home"} className="text-sm sm:text-base">
                  Dashboard
                </Link>
              </li>
              <li className="mx-1 sm:mx-2 cursor-pointer px-2 sm:px-3 py-1 rounded-full border hover:bg-gray-50">
                <Link to={"./manageStock"} className="text-sm sm:text-base">
                  Inventory
                </Link>
              </li>
              <li className="mx-1 sm:mx-2 cursor-pointer px-2 sm:px-3 py-1 rounded-full border hover:bg-gray-50">
                <Link to={"./manageInvoice"} className="text-sm sm:text-base">
                  Invoice
                </Link>
              </li>
            </ul>
          </div>
          <div className="mr-4">
            <img
              src={`https://ui-avatars.com/api/?name=${profile?.name}`}
              alt="profile"
              className="w-10 h-10 rounded-full"
              onClick={handleOnprofile}
            />
          </div>
        </div>
      </nav>
      {/* Logout Confirmation Modal */}
      {sureLogoutModel && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div
              className="fixed inset-0 transition-opacity"
              onClick={handleCloseModal}
            >
              <div className="absolute inset-0 bg-black opacity-80"></div>
            </div>
            <div className="relative bg-white rounded-lg p-6 mx-auto">
              <div className="flex flex-col items-center justify-center">
                <div className="text-xl font-semibold text-gray-800 mb-2">
                  Are you sure you want to logout?
                </div>
                <div className="flex space-x-4">
                  <button
                    onClick={handleLogout}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Yes
                  </button>
                  <button
                    onClick={handleCloseModal}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* model for profile  */}
      {isProfileOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex justify-end">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={handleOnCloseProfile}
          >
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
          <div className="bg-white  overflow-hidden shadow-xl relative w-64 ">
            <button
              className="absolute top-0 right-0 m-3 text-gray-600 hover:text-gray-800"
              onClick={handleOnCloseProfile}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="p-8 flex flex-col">
              <div>
                <div className="flex items-center justify-center mb-4">
                  <img
                    src={`https://ui-avatars.com/api/?name=${profile?.name}`}
                    alt="profile"
                    className="w-20 h-20 rounded-full"
                  />
                </div>
                <h2 className="text-xl font-bold text-gray-800 mb-2 capitalize">
                  {profile?.name}
                </h2>
                <p className="text-sm text-gray-600">{profile?.email}</p>
              </div>

              <div className="flex justify-center">
                <button
                  onClick={() => {
                    setSureLogoutModel(true);
                    setIsProfileOpen(false);
                  }}
                  className="text-sm text-red-600 underline hover:text-red-800 px-10 mt-20 py-1 bg-red-50 border rounded-full"
                >
                  Logout
                </button>
              </div>

              {/* brand image  */}
              <div className="opacity-60 flex items-center justify-center">
                <img src={logo} alt="logo" className="absolute bottom-0 mx-auto" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopNavbar;
