import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../../component/admin-sidebar/Sidebar";
import { useSelector } from "react-redux";
import TopNavbar from "../../component/navbar/TopNavbar";
import Footer from "../../component/footer/Footer";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { isAuthenticate } = useSelector((state) => state.loadUser);

  // Use useEffect to perform side effects after the component has mounted
  useEffect(() => {
    // Function to check if cookies are available
    // if the user does not exist
    if (!isAuthenticate) {
      navigate("../login");
    }
  }, [isAuthenticate, navigate]);

  return (
    <>
      {/* Fixed top navbar */}
      <div className="fixed top-0 left-0 w-full z-50">
        <TopNavbar />
      </div>

      {/* Content */}
      <div className="mt-[100px]">
        {/* Adjust mt-16 (margin-top) to match the height of your top navbar */}
        <Outlet />
      </div>
    </>
  );
};

export default AdminDashboard;
