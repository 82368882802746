import React from "react";

const StockCalculationBar = ({ stock }) => {
  //calculate total amount
  let totalAmount = 0;
  let totalWholeAmount = 0;
  let totalRetalAmount = 0;
  for (const item of stock) {
    totalAmount += item.totalAmount;
    totalWholeAmount += item.wholesaleAmount * item.productQnty;
    totalRetalAmount += item.retailAmount * item.productQnty;
  }

  return (
    <div className="overflow-x-auto">
      <table className="w-full bg-white border border-gray-300 mb-2">
        <thead>
          <tr className="text-[10px] sm:text-[12px] md:text-[12px] lg:text-[12px] text-gray-500">
            <th className="py-1 px-2 sm:px-4 ">Product Count</th>
            <th className="py-1 px-2 sm:px-4 ">Total Amount</th>
            <th className="py-1 px-2 sm:px-4 ">Total Wholesale Amount</th>
            <th className="py-1 px-2 sm:px-4 ">Total Retail Amount</th>
            <th className="py-1 px-2 sm:px-4 ">Estimate Profit</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center text-lg sm:text-2xl md:text-2xl lg:text-2xl text-gray-600">
            <td className="py-1 px-2 sm:px-4 border-b">{stock.length}</td>
            <td className="py-1 px-2 sm:px-4 border-b">
              {totalAmount.toFixed(2)}
            </td>
            <td className="py-1 px-2 sm:px-4 border-b">
              {totalWholeAmount.toFixed(2)}
            </td>
            <td className="py-1 px-2 sm:px-4 border-b">
              {totalRetalAmount.toFixed(2)}
            </td>
            <td className="py-1 px-2 sm:px-4 border-b">
              {(totalWholeAmount - totalAmount).toFixed(2)}
            </td>
          </tr>
          {/* Add more rows as needed */}
        </tbody>
      </table>
    </div>
  );
};

export default StockCalculationBar;
