import React from 'react'
import StockInventary from '../../component/admin-stock-inventary/StockInventary'

function AddNewStock() {
  return (
    <div>
      <StockInventary/>
    </div>
  )
}

export default AddNewStock