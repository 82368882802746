import React from 'react'

const StaffPage = () => {
  return (
    <div>
      <h1 className='text-2xl text-center font-bold p-4 bg-slate-200 uppercase'>Staff Available Soon!</h1>
    </div>
  )
}

export default StaffPage