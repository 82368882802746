import React from 'react'

const HeadingTitle = ({title}) => {
  return (
    <>
    <h2 className="p-1 px-4 text-slate-500 text-lg font-bold mb-4 text-left uppercase bg-gray-100 border-b shadow-sm">
      {title}
    </h2>
  </>
  )
}

export default HeadingTitle