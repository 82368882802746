import axios from "axios";
import config from "../config/config";

export const loadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "LOAD_USER_REQUEST",
    });

    //LOGIN
    const { data } = await axios.get(`${config.server_url}/users/me`, {
      withCredentials: true,
    });

    const { success } = data;
    if (success) {
      dispatch({
        type: "LOAD_USER_SUCCESS",
        payload: data.user,
      });
    }
  } catch (error) {
    dispatch({
      type: "LOAD_USER_FAILURE",
      payload: error,
    });
  }
};
