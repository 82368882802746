import React from 'react'
import InvoiceManagement from '../../component/admin-invoice-management/InvoiceManagement'

const ManageInvoice = () => {
  return (
    <div>
       <InvoiceManagement/>
    </div>
  )
}

export default ManageInvoice