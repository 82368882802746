import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../actions/LoginAction";
import { loadUser } from "../../actions/LoadUserAction";
import logo from "../../assets/BirulyGrocery.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../component/utilities/Loading";
import Footer from "../../component/footer/Footer";

const Login = () => {
  const { isAuthenticate } = useSelector((state) => state.loadUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const isAuthenticate = localStorage.getItem("isAuthenticate")

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Redirect to dashboard if already authenticated
    if (isAuthenticate) {
      navigate("/dashboard/home"); // Adjust the route as needed
    }
  }, [isAuthenticate, navigate]);

  //login
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email) {
      toast.error("Please Enter Email", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!password) {
      toast.error("Please Enter Password", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    try {
      // You can add your login logic here
      await dispatch(login(email, password)).then(() => dispatch(loadUser()));
      setLoading(false);
      navigate("/dashboard/home");
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  //  if user is exist then
  // if (isAuthenticate) {
  //   toast.success(`Welcome back ${user.name}`, {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  //   navigate("../dashboard");
  // }

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="h-screen bg-gray-50">
            {/* // navbar  */}
            <nav className="flex justify-between items-center bg-white p-1 shadow-md">
              <div className="flex items-center">
                <img src={logo} alt="logo" className="w-16 sm:w-20 mr-4" />
                <Link
                  to="./home"
                  className="text-lg sm:text-xl font-bold text-[#39B54A] font-sans"
                >
                  BirulyGrocery
                </Link>
              </div>
              <div className="flex items-center">
                <div className="overflow-x-auto">
                  <ul className="flex mx-2 sm:mx-6">
                    <li className="mx-1 sm:mx-2 cursor-pointer px-2 sm:px-3 py-1 rounded-full border hover:bg-gray-50">
                      <Link to={"#"} className="text-sm sm:text-base">
                        Home
                      </Link>
                    </li>
                    <li className="mx-1 sm:mx-2 cursor-pointer px-2 sm:px-3 py-1 rounded-full border hover:bg-gray-50">
                      <Link to={"#"} className="text-sm sm:text-base">
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="mr-4">
            <img
              src={`https://ui-avatars.com/api/?name=${profile.name}`}
              alt="profile"
              className="w-10 h-10 rounded-full"
              onClick={handleOnprofile}
            />
          </div> */}
              </div>
            </nav>
            {/* // end navbar  */}

            {/* login panel */}
            <div className="col-span-1 mt-20">
              <div className=" min-w-fit flex items-center justify-center">
                <div className=" bg-white shadow-2xl rounded-lg px-8 pt-6 pb-8 mb-4 w-72">
                  <div className="text-center mb-3">
                    {/* <span className="font-bold text-xl uppercase ">Sign In</span> */}
                    <div className="flex justify-center items-center mx-auto ">
                      <img src={logo} alt="logo" className="w-40 h-40" />
                    </div>
                  </div>
                  {/* <hr className="mb-2" /> */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="email"
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                      id="password"
                      type="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="flex items-center justify-between w-full ">
                    <button
                      className="bg-green-900 w-full hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg   focus:outline-none focus:shadow-outline"
                      onClick={(e) => handleLogin(e)}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* copyright */}
            {/* <div>
                <span>All &copy; copyright reserved. 2004-2024</span>
              </div> */}

            <div className="absolute bottom-0 w-full ">
              <Footer />
            </div>
          </div>
        </>
      )}

      <ToastContainer />
    </div>
  );
};

export default Login;
