import axios from "axios";
import config from "../config/config";

export const login = (email, password) => async (dispatch) => {
  // const navigate = useNavigate()
  try {
    dispatch({
      type: "LOGIN_REQUEST",
    });

    //LOGIN
    const { data } = await axios.post(
      `${config.server_url}/users/login`,
      {
        email,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    // navigate("../dashboard")
    const { success, message } = data;
    if (success) {
      alert(message);

      dispatch({
        type: "LOGIN_SUCCESS",
        payload: data.message,
      });
      localStorage.setItem("isAuthenticate", "true");
    }
  } catch (error) {
    alert(error.response.data.message);
    dispatch({
      type: "LOGIN_FAILURE",
      payload: error.response.data.message,
    });
  }

  // return <div></div>
};
