import React from 'react'

const Reports = () => {
  return (
    <div>
      <h1 className='text-2xl text-center font-bold p-4 bg-slate-200 uppercase'>Sale Reports Available Soon!</h1>
    </div>
  )
}

export default Reports