import React from "react";
import loading from "../../assets/truck.gif";

const Loading = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen bg-white">
      <img src={loading} alt="loading" className="w-64 h-64" />
      <p className="mt-4 text-gray-600">Please wait...</p>
    </div>
  );
};

export default Loading;
